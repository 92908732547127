@import '../../../styles/variables';

.menuNav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 3rem;
	border-radius: $border-radius $border-radius 0 0;
	background-color: white;
	box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
	// z-index: 100;

	nav {
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
	}

	&.active {
		border-radius: 0;
	}

	&__buttonMenu,
	&__buttonWeasel,
	&__buttonMap,
	&__buttonSettings {
		background: none;
		border: none;
		color: #fff;
		font-size: 1.5rem;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;

		&:hover {
			background-color: rgba(255, 255, 255, 0.1);
		}

		&.button-active {
			// background-color: $color2;
			.material-symbols-outlined,
			.menuNav__buttonWeasel__span {
				color: $color2;
				border-radius: 50%;
				background-color: rgba($color2, 0.3);
				box-shadow: 0px 0px 10px rgba($color2, 0.6);
			}
		}

		.menuNav__buttonWeasel__span {
			display: flex;
			align-items: center;
		}

		.menuNav__buttonWeasel__img {
			height: 2rem;
			width: 2rem;
		}

		.material-symbols-outlined {
			color: $color1;
			font-size: 2rem;
		}
	}

	&__buttonMap {
		display: inline;
	}

	&__containeMenu {
		position: absolute;
		bottom: 3rem;
		width: 100%;
		height: 17rem;
		background-color: #fff;
		border-radius: $border-radius $border-radius 0 0;
		box-shadow: 0px -5px 5px rgba(0, 0, 0, 0.2);
		overflow: auto;
		overflow-x: hidden;
		// z-index: 999;
		display: none;
		transition: height 0.5s ease; /* Ajoute une transition pour la hauteur */

		// Activer le menu actuellement sélectionné
		&.containeMenu-active {
			display: block; /* Affiche le conteneur */
		}

		&.maximized {
			height: 83vh;
		}

		&__topBtn {
			position: fixed;
			right: 10px;
			bottom: 3.5rem;
			width: 2.5rem;
			height: 2.5rem;
			border: none;
			background-color: rgba($color1, 0.4);
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
			cursor: pointer;
			border-radius: $border-radius;
			box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

			transition: transform 0.2s ease, box-shadow 0.2s ease,
				background-color 0.3s ease;

			// Effet au survol
			&:hover {
				background-color: rgba($color1, 0.7);
				box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
			}

			// Effet au clic
			&:active {
				transform: scale(0.9);
				box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
				background-color: rgba(204, 204, 204, 0.8);
			}

			// Animation d'onde au clic
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 100%;
				border-radius: 50%;
				background-color: rgba(204, 204, 204, 0.5);
				opacity: 0;
				transform: scale(0);
				transition: transform 0.5s ease, opacity 0.5s ease;
			}

			&:active::after {
				transform: scale(1.5);
				opacity: 0;
			}
		}

		&__containeButton {
			// position: fixed;
			display: flex;
			justify-content: flex-end;
			width: 100%;
			z-index: 999;
		}

		&__button {
			border: none;
			border-radius: 0 $border-radius 0 $border-radius;
			background-color: rgba($color1, 0.3);
			cursor: pointer;
			z-index: 999;

			span {
				font-size: 2.75rem;
				pointer-events: none;
				color: $color2;
			}
		}

		&__button:hover {
			background-color: rgba($color1, 0.7);
			span {
				color: white;
			}
		}
	}

	&__buttonWeasel {
		display: none;
	}
}
