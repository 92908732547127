@import '../../../styles/variables';

.userPresentation {
	width: 95%;
	margin-bottom: 1.5rem;
	border: solid 2px $gris;
	border-radius: $border-radius;

	&__name {
		margin: 20px 2rem;
		#presentationName {
			font-weight: bold;
		}

		#presentationHead {
			margin: 10px 0;
			text-align: center;
		}

		#presentationTitle {
			word-break: break-word;
			text-align: center;
			color: white;
			background-color: $color1;
			padding: 5px;
			border-radius: $border-radius/2;
		}

		#presentationMessage {
			text-align: justify;
		}

		#presentationMessageCurious {
			margin-top: 10px;
			text-align: justify;
		}
	}

	&__badges {
		margin: 0 2rem 10px 2rem;
		padding: 10px 0 5px 0;
		background-color: rgba($color2, 1);
		border: solid 2px $color1;
		border-radius: $border-radius;
		display: flex;
		justify-content: space-evenly;
		align-items: center;

		img {
			width: 128px;

			background-color: rgba($gris, 0.8);
			border-radius: $border-radius;
			border: solid 3px rgba($gris, 1);
			box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2),
				// Ombre en bas à droite
				-2px -2px 8px rgba(
						255,
						255,
						255,
						0.5
					); // Effet lumineux en haut à gauche

			transition: transform 0.2s ease, box-shadow 0.2s ease; // Ajoute des transitions pour l'interaction

			// Effet de survol pour un léger agrandissement et ombre accentuée
			&:hover {
				transform: translateY(-1px); // Simule un effet d'élévation
				box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.4),
					-4px -4px 12px rgba(255, 255, 255, 0.7);
			}
		}
	}

	&__lvlBadges {
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-around;
		align-items: center;
		img {
			width: 48px;
		}
	}

	&__badge {
		margin-bottom: 5px;
		display: flex;
		flex-direction: column;
		align-items: center;
		span {
			text-align: center;
			font-size: 0.65rem;
			color: white;
		}
	}
}
