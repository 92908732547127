@import '../../../styles/variables';

.carrousel {
	position: relative;
	width: 90%;
	background-color: rgba($gris, 0.65);
	overflow: hidden; // Empêche les images de dépasser du carrousel
	border-radius: $border-radius;

	// Conteneurs flous à gauche et à droite
	&__blur {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 100%; // Largeur du flou sur chaque côté
		height: 100%;
		background-size: cover;
		background-position: center;
		filter: blur(10px); // Effet de flou
		// z-index: 1; // Derrière l'image principale
		// Transition pour un effet fluide lors du changement de photo
		transition: background-image 0.05s ease-in-out;
	}

	&__content {
		width: 100%;
		height: 12rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	&__buttonModal {
		width: 100%;
		height: 12rem;
		padding: 0;
		border: none;
		background: none;
		cursor: pointer;
		z-index: 1; // Place l'image au-dessus des arrière-plans floutés

		// Image principale
		.carrousel__img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: $border-radius/2;
			object-fit: contain; // Empêche la déformation
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
		width: 2.5rem;
		border: none;
		background-color: rgba($color1, 0.4);
		transition: background-color 0.3s ease-in-out;
		z-index: 2; // Assure que les boutons sont au-dessus des images

		span {
			font-size: 3rem; // Taille de la flèche ajustée pour être visible mais pas trop grande
			color: rgba(white, 0.8);
		}

		&:hover {
			background-color: rgba($color1, 0.8);
			cursor: pointer;

			span {
				// Amélioration de la visibilité de l'icône au survol
				color: rgba(white, 1);
			}
		}
	}

	&__button.left {
		z-index: 2;
		// position: absolute;
		left: 0;
		border-radius: $border-radius 0 0 $border-radius;
	}

	&__button.right {
		z-index: 2;
		// position: absolute;
		right: 0;
		border-radius: 0 $border-radius $border-radius 0;
	}

	&__index {
		z-index: 2;
		pointer-events: none;
		position: absolute;
		bottom: 5px;
		left: 50%;
		transform: translateX(-50%); // Centrage horizontal du compteur
		background: rgba(0, 0, 0, 0.5); // Fond pour une meilleure lisibilité
		color: white;
		opacity: 0.6;
		padding: 0.25rem 0.5rem;
		border-radius: $border-radius;
		font-size: 0.75rem;
	}

	&__modal {
		position: relative;
		display: flex;
		justify-content: center;
		overflow: hidden; // Cache tout débordement

		.carrousel {
			margin: 1rem;
			width: 100%;

			&__button.left {
				position: absolute;
			}

			&__button.right {
				position: absolute;
			}

			&__content {
				z-index: 1;
				height: 75vh;

				.carrousel__img {
					z-index: 1;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: contain; // Empêche la déformation
				}
			}
		}
	}
}

.carrousel-reduceview {
	width: 100%;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;

	.carrousel__inner-reduceview {
		width: auto;
		margin: 0 0.5rem;
		display: flex;
		flex-direction: row;
		justify-content: center;

		.reduceview-img {
			height: 6rem;
			width: 9rem;
			max-width: 50%;
			margin: 0.25rem 0.25rem 0.5rem 0.25rem;
			object-fit: cover;
			border-radius: $border-radius;
		}

		.onePicture {
			max-width: 100%; //largeur de l'image si une seul photo en BDD
		}
	}
}

.devButton {
	position: absolute;
	opacity: 20%;
	top: 0;
	left: 0;
}
