@import '../../../styles/variables';
@import '../../../styles/mixins';

.tagsBox {
	@include elementMenu;

	&__list {
		margin: 0 1rem;
		padding: 1rem 0.5rem;
		box-sizing: border-box;
		width: 90%;
		border: solid 2px $color1;
		border-radius: $border-radius * 2;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		gap: 5px;
		background-color: $color2;

		button {
			min-width: 4.5rem;
			border: solid 2px $gris;
			border-radius: $border-radius * 2;
			padding: 5px 10px;
			font-weight: bold;
			color: black;
			background: white;
			cursor: pointer;
		}

		.selected {
			background-color: $color1;
			color: white;
		}
	}

	&__cityAndSlider {
		@include formMenu;

		#slider {
			width: 100%;
			margin-top: 10px;
			padding: 0;
		}
	}

	&__majButton {
		@include button;
		margin: 0 0 1rem 0;
	}

	&__confirmation {
		text-align: center;
		margin: 0;
	}
}
