@import '../../../styles/variables';
@import '../../../styles/mixins';

.placeCard-aroundYou,
.placeCard-nextOuting,
.placeCard-favoris {
	@include elementMenu;

	h3 {
		margin: 0 0.5rem;
		word-break: break-word;
	}

	#favoris__title {
		margin: 0.5rem;
	}

	.placeCard__tags {
		margin: 0 0.25rem 0.5rem 0.5rem;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 5px;

		.placeCard__tag {
			border: solid 2px $gris;
			border-radius: $border-radius * 2;
			padding: 5px 10px;
			font-weight: bold;
			text-align: center;
			background: white;
		}

		.selected {
			background-color: $color2;
			color: white;
		}
	}

	.placeCard__no-images {
		margin: 0.5rem;
		font-weight: lighter;
		font-size: 0.75rem;
	}

	.placeCard__noteAndPosition {
		// width: 100%;
		margin: 0.25rem 0.5rem;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.favoriteBtn {
			width: 1rem;
			height: 1rem;
			margin: 0 1rem 0 0;
			padding: 0;
		}
	}

	.resendEmail {
		background: none;
		border: none;
		margin: 10px;
		padding: 0;
		color: $color2;
		cursor: pointer;

		&:hover {
			text-decoration: underline;
		}
	}
}

.placeCard-nextOuting {
	.placeCard__noteAndPosition {
		width: 95%;
	}

	&__title {
		max-width: 85%;
		word-break: break-word;
		display: flex;
		align-content: center;
		align-items: center;
		// transform: translateX(1rem);

		h3 {
			margin: 0;
			transform: translateX(1rem);
		}

		button {
			width: 1rem;
			margin-left: 25px;
			background: none;
			border: none;
			cursor: pointer;
			color: $color1;

			&:hover {
				color: $color2;
			}
		}
	}

	h4 {
		width: 100%;
		margin: 0rem 0 0 2rem;
		font-size: 1rem;
	}

	.updateBtn {
		@include reduceBtn;
		padding: 0;

		.reportButton {
			.buttonText {
				color: $color2;
			}

			&:hover {
				background-color: $color2;
			}
		}
	}

	.placeCard__tags {
		width: 90%;
		margin: 0.5rem 0 0 0;
	}

	.placeCard__adresse-title {
		width: 90%;
		margin: 1rem 0 0 0;

		.adresse-title {
			margin: 0;
			font-weight: bold;
		}

		.titleAndBtn {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		#description {
			display: block;
			margin-top: 2px;
			text-align: justify;
		}

		#created {
			margin-top: 10px;
			font-weight: lighter;
			font-size: 0.75rem;
			text-align: right;
			span {
				font-size: 0.75rem;
				font-style: italic;
			}
		}
	}

	.placeCard__copyBtn {
		margin: 0 0 0 10px;
		width: 1rem;
		height: 1rem;
		padding: 0;
		border: none;
		background: none;
		color: $color1;
		cursor: pointer;

		&:hover {
			color: $color2;
		}
	}

	.placeCard__copy-message {
		margin: 0 0 0 10px;
		color: $color4;
	}

	&__ModificationButton {
		@include button;
	}
}

.placeCard-aroundYou,
.placeCard-favoris {
	margin-bottom: 25px;
	border: none;

	&:hover {
		transition: 0.1s;
		transform: scale(1.01);
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.4);
	}

	&.default {
		border: solid 2px $gris;
		border-radius: $border-radius * 2;
	}

	&.default:hover {
		transition: 0s;
		transform: none;
		box-shadow: none;
	}

	p {
		width: 80%;
		text-align: center;

		span {
			background-color: $color1;
			color: white;
			padding: 2px;
			font-weight: bold;
		}
	}

	.add-place-button {
		@include button;
		display: flex;
		justify-content: space-around;
		align-items: center;
	}

	&__button {
		width: 100%;
		height: 100%;
		border: solid 2px $gris;
		box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
		border-radius: $border-radius * 2;
		display: flex;
		flex-direction: column;
		background-color: white;
		cursor: pointer;
	}

	&.footer {
		width: 100%;
		margin: 2rem 0 0 0;
		border-top: 2px solid $color2;
		border-radius: 0;
		background-color: rgba($gris, $alpha: 0.4);

		.footer__message {
			margin: 1rem 1rem 0 1rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;

			p {
				width: 50%;
				color: $color2;
				border-radius: 0 $border-radius $border-radius 0;
				transform: translateX(-10px);
			}

			img {
				z-index: 1;
				height: 7rem;
			}
		}
		p {
			background-color: rgba(#fff, 0.8);
			padding: 10px;
			border-radius: $border-radius;
		}
	}
}
