@import '../../../styles/variables';
@import '../../../styles/mixins';

.favoritesList {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	h4,
	p {
		max-width: 80%;
		text-align: center;
	}
}
