@import '../../../styles/variables';

.miniMapContainer {
	height: 15rem;
	width: 25rem;
	max-width: 16rem;
	margin: 0.5rem 0 1rem 0;
	border: solid 5px $color2;
	border-radius: 4px;

	.mapboxgl-ctrl-bottom-left,
	.mapboxgl-ctrl-bottom-right {
		z-index: auto;
	}

	.marker {
		display: flex;
		align-items: center;
		justify-content: center;
		color: #a2341b;

		&:hover {
			cursor: pointer;
			color: #ef4f2b;
		}

		&.highlighted-marker {
			color: #ef4f2b; // Couleur spéciale pour le marker sélectionné
		}
	}

	.material-symbols-outlined {
		font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 48;
		font-size: 1.5rem;
	}
}
