@import '../../../styles/variables';
@import '../../../styles/mixins';

.settingsUser {
	@include elementMenu;
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		text-align: center;
	}

	&__majPasswordForm,
	&__majEmailForm,
	&__contactPreference {
		h4 {
			text-align: center;
			margin: 1.5rem 1rem 1rem 1rem;
		}
		display: flex;
		flex-direction: column;
		align-items: center;

		&__tick {
			display: flex;
			gap: 4rem;

			// Style pour les inputs radio
			input[type='radio'] {
				appearance: none; // Supprime le style natif des radio buttons
				width: 20px; // Taille du carré
				height: 20px;
				border: 2px solid $color2; // Couleur de la bordure
				border-radius: 4px; // Pour des carrés avec des coins légèrement arrondis
				cursor: pointer;
				transition: background-color 0.2s ease, border-color 0.2s ease;

				// Effet au survol
				&:hover {
					border-color: $color1;
				}

				// Lorsque l'élément est coché
				&:checked {
					background-color: $color1; // Fond coloré
					border-color: $color1;

					// Ajoute une icône ou un effet pour indiquer qu'il est sélectionné
					position: relative;

					&::after {
						content: 'X'; // Utilisé pour le check
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						// border-radius: 2px; // Maintient un carré intérieur
					}
				}
			}

			label {
				display: flex;
				align-items: center;
				cursor: pointer;
			}
		}
	}

	h3 {
		margin-bottom: 10px;
	}

	input {
		display: block;
		width: 90%;
		padding: 10px;
		margin-bottom: 10px;
		border: 1px solid #ddd;
		border-radius: 4px;
	}

	button {
		@include button;
	}

	.message {
		max-width: 80%;
		margin-top: 20px;
		text-align: center;
		&.success {
			color: green;
		}
		&.error {
			color: red;
		}
	}

	&__footer {
		width: 100%;
		margin-top: 1rem;
		padding: 2px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: rgba($gris, $alpha: 0.8);
		a {
			margin-left: 1rem;
			font-size: 0.85rem;
			text-decoration: none;
			color: #fff;

			&:hover {
				color: black;
				text-decoration: underline;
			}
		}

		.signalButton-contact {
			width: auto;

			.material-symbols-outlined {
				color: #fff;
			}
		}
	}
}

.settingsUser__logout {
	@include button;
}
