@import '../../../styles/variables';
@import '../../../styles/mixins';

.loading {
	background-color: rgba($color2, $alpha: 0.7);
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-content: center;
	text-align: center;
	user-select: none;

	&__info {
		position: absolute;
		top: 2rem;
		left: 150px;
		transform: translateX(-50%);
		width: 10rem;
		max-width: 90%;
		padding: 1rem;
		text-align: center;
		background-color: rgba(
			255,
			255,
			255,
			0.7
		); // Fond blanc semi-transparent
		border: solid 2px $gris;
		border-radius: $border-radius;
		box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); // Légère ombre portée
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 0.5rem; // Espacement entre l'icône et le texte
		animation: bounce 1.5s ease-in-out infinite; // Animation subtile de rebond

		.icon {
			font-size: 1.5rem;
			color: $color2; // Couleur pour l'icône de géolocalisation
		}

		.text {
			margin: 0;
			font-size: 1rem;
			font-weight: bold;
			color: $gris;
			line-height: 1.5;
		}
	}

	h1 {
		margin: 3rem 0rem 3rem 0rem;
		font-size: 3rem;
		font-weight: bold;
		color: $gris;
	}

	.logo {
		border-radius: 50%;
		border: solid 2px $gris;
		background-color: $color2;
	}

	.gifLogo {
		width: 10rem;
		height: 10rem;
	}

	.txtLoading {
		margin: 3rem;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.txt {
		font-weight: bold;
		font-size: 2rem;
		color: $gris;
	}

	.points {
		font-weight: bold;
		font-size: 2rem;
		letter-spacing: 0.1rem;
		color: $gris;
		animation: load 2s linear infinite;
	}

	// // Animation de rebond pour l'élément info
	// @keyframes bounce {
	// 	0%,
	// 	20%,
	// 	50%,
	// 	80%,
	// 	100% {
	// 		transform: translateY(0); // Position normale
	// 	}
	// 	40% {
	// 		transform: translateY(-20px); // Monte de 20px
	// 	}
	// 	60% {
	// 		transform: translateY(-10px); // Redescend légèrement
	// 	}
	// }

	// Animation des points de suspension "Chargement..."
	@keyframes load {
		0% {
			color: #70707080;
		}

		100% {
			color: $gris;
		}
	}

	.points:nth-child(1) {
		animation-delay: 0.5s;
	}

	.points:nth-child(2) {
		animation-delay: 1s;
	}

	.points:nth-child(3) {
		animation-delay: 1.5s;
	}
}
