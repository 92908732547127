@import '../../styles/variables';

.cgu {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 3rem auto;
	text-align: center;

	&__title {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0 2rem;

		img {
			max-width: 10rem;
		}
		div {
			text-align: left;

			h1 {
				color: $color1;
			}

			h2 {
				color: $gris;
			}
		}
	}

	&__corps {
		margin: 2rem;
		text-align: justify;

		h2 {
			font-size: 1.5rem;
			color: $color2;
		}
	}

	a {
		margin: 2rem 0 5rem 0;
		font-size: 1rem;
		color: black;
		text-decoration: underline;

		&:hover {
			color: $color2;
		}
	}
}
