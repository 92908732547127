@import '../../styles/variables';
@import '../../styles/mixins';

.admin {
	@include bgMenu;
	align-items: initial;

	nav {
		position: fixed;
		top: 0;
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 10px;
		background-color: #ccccccc0;

		a {
			color: $color2;

			&:hover {
				color: $color1;
			}
		}

		.admin__refresh-button {
			margin: 0;
			padding: 0;
			background: none;
			border: none;
			color: $color2;
			cursor: pointer;

			&:hover {
				color: $color1;
			}

			.material-symbols-outlined {
				font-size: 1.5rem;
			}
		}
	}

	h1 {
		margin-top: 72px;
		text-align: center;
	}

	.admin__console {
		display: flex;
		flex-direction: column;
		// background-color: rgba($color: $gris, $alpha: 0.5);
		&-delete {
			margin: 0;
			padding: 0;
			background: none;
			border: none;
			cursor: pointer;

			&:hover {
				color: white;
			}
		}

		&__news,
		&__updates,
		&__reports,
		&__contacts {
			h2 {
				text-align: center;
				align-content: center;
				width: 100%;
				height: 5rem;
				margin: 2rem 0 0 0;
				border-radius: $border-radius $border-radius 0 0;
				padding: 10px 0;
				cursor: pointer;
			}

			&-list {
				padding: 0 10px 20px 10px;
				max-height: 60vh;
				overflow: auto;
				background-color: #ccccccc0;
				border-bottom: solid 2px $gris;
			}

			.proposal {
				@include elementMenu;
				width: auto;
				margin: 1rem 0 0 0;
				padding: 10px;
				word-break: break-all;

				&-id {
					width: 100%;
					display: flex;
					justify-content: space-between;

					div {
						display: flex;
						flex-direction: column;
					}
				}

				&-item {
					@include elementMenu;
					margin: 1rem 0 0.5rem 0;
					align-items: flex-start;
					background-color: #fff;
					box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.2);
					cursor: pointer;

					&-user {
						margin: 10px;
						span {
							margin: 0;
						}
					}

					&-place {
						margin: 10px;
						h3 {
							margin: 0;
							font-size: 1rem;
						}
						p {
							margin: 0;
						}
					}
				}
			}
		}

		&__news {
			h2 {
				background-color: $color1;
			}
			.proposal {
				background-color: #d8b4585a;
			}
		}

		&__updates {
			h2 {
				background-color: $color2;
			}

			.proposal {
				background-color: #58bcd85a;
			}
		}

		&__reports {
			h2 {
				background-color: $color3;
			}

			.proposal {
				background-color: #bcd8585a;
				&-alerte {
					background-color: #ff000084;
				}
			}
		}

		&__contacts {
			h2 {
				background-color: $color4;
			}

			.proposal {
				background-color: #7cd8585a;
				&-alerte {
					background-color: #ff000084;
				}
			}
		}
	}

	.report-modal-content {
		.report,
		.contact {
			border-radius: $border-radius;
			background-color: #bcd8585a;
			padding: 20px;

			p {
				margin: 0 0 10px 0;
			}

			&-comment {
				background-color: #fff;
				border-radius: $border-radius;
				padding: 10px;
			}
		}

		.report-modal-actions {
			display: flex;
			justify-content: space-around;
			gap: 10px;

			button {
				@include button;
				width: 100%;
			}
		}
	}
}
