@media (min-width: 768px) {
	.authChoice__main {
		&__body {
			justify-content: space-evenly;

			.authChoice__main__info {
				flex-direction: row;

				.authChoice__card {
					.card-text {
						text-align: left;
						transform: translateX(-2rem);
					}
				}
			}
		}
	}

	.tagsBox-signup {
		width: 50%;
	}

	.menuNav {
		width: 21.5rem;

		.containeMenu-active {
			height: 95vh;
		}

		// &__buttonMap {
		// 	display: none;
		// }

		.maximized {
			height: 95vh;
		}

		.menuOpen__head {
			margin: 2rem 0 2rem 0;
		}

		&__containeMenu__topBtn {
			right: auto;
			left: 18rem;
		}
	}

	.modal {
		min-width: 35rem;
		width: 40vw;
		max-height: 80vh;
	}

	.boardPro__nav {
		&__titleGroupe {
			justify-content: left;
			padding: 0;
		}

		&__back {
			margin-right: 2rem;
		}

		&__title {
			&--img {
				display: block;
			}
		}

		&__btnGroupe {
			display: flex; // On cache le menu bandeau
		}

		&__hamburger {
			display: none; // Affiche le menu hamburger
		}

		&__dropdown.active {
			display: none;
		}
	}

	// .signalForm {
	// 	width: 40vw;
	// 	max-height: 80vh;
	// }

	.citySearch {
		form {
			margin-left: 0;

			input,
			button {
				height: 48px;
				// padding: 0;
				box-sizing: border-box;
			}

			button {
				width: 48px;
				padding: 0;
				transform: translateX(0);
				border-left: none;
				border-radius: 0 $border-radius $border-radius 0;
			}
		}
	}

	.carrousel {
		&__modal {
			// width: 65%;
			overflow: hidden;
			height: auto;
		}
	}

	.menuNav__containeMenu__button {
		display: none;
	}

	.addPlaceForm {
		width: 80%;

		&__picturesAndPreviews,
		&__picturePreviews {
			justify-content: flex-start;
		}

		// &__modal {
		// 	max-width: 40rem;
		// }
	}

	.map-controls {
		@media (min-height: 500px) {
			flex-direction: column;
			align-items: end;

			&__button,
			.reportButton {
				justify-content: end;

				.buttonText {
					display: inline;
					font-size: 14px;
					margin-right: 0.5rem;
					transform: translateX(
						10px
					); // Décalage initial pour l'animation
					opacity: 0;
					pointer-events: none;
					white-space: nowrap; // Empêche le retour à la ligne
					transition: opacity 0.3s ease, transform 0.3s ease;
				}

				&:hover {
					width: auto;
					padding-left: 1rem;
					color: #fff;
					border-radius: 20px;
					.buttonText {
						pointer-events: auto;
						opacity: 1;
						transform: translateX(
							0
						); // Animation vers sa position finale
						color: #fff;
					}
				}
			}
		}
	}

	.mapContainer {
		.mapboxgl-ctrl-attrib {
			position: static;
			bottom: 0;
			a {
				font-size: 12px;
			}
			// display: none;
		}
	}

	.addEventForm {
		max-width: 35rem;

		&__mapPreview {
			.miniMapContainer {
				max-width: 25rem;
			}
			p {
				max-width: 25rem;
			}
		}

		.eventInfo__item {
			flex-direction: row;
		}

		&__preview {
			&__button {
				flex-direction: row;
				gap: 2rem;
			}
		}
	}

	.eventCard__infos__datesAndHours__price {
		white-space: nowrap;
	}

	.addEventForm .popup {
		&.changelog,
		&.preferences {
			max-width: 25rem;
		}
	}

	.admin {
		.admin__console {
			display: grid;
			grid-template-columns: 1fr 1fr; // Deux colonnes de taille égale
			gap: 1.5rem;
			padding: 0 10%;

			// Style pour les colonnes
			&__column {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				height: auto; // Ajuste la hauteur automatiquement selon le contenu
			}
		}
	}

	.cgu {
		&__title {
			display: flex;
			flex-direction: row;
			margin: 0 2rem;
		}
	}

	.event__history {
		&__list-ongoing-events,
		&__list-past-events {
			.ongoing-events,
			.past-events {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
}

// @media (min-width: 1200px) {
// 	font-size: 20px;
// }

@media (min-width: 1780px) {
	.authChoice__main {
		&__body {
			flex-direction: row;
			justify-content: space-evenly;

			.authChoice__main__info {
				flex-direction: row;

				.authChoice__card {
					// &.card1 {
					// }

					&.card2 {
						margin-top: 2rem;
					}

					&.card3 {
						margin-top: 4rem;
					}
				}
			}
		}
	}

	.menuNav {
		width: 23rem;
	}

	.citySearch {
		form {
			input,
			button {
				height: 64px;
			}

			button {
				width: 64px;
			}
		}
	}

	.event__history {
		&__list-ongoing-events,
		&__list-past-events {
			.ongoing-events,
			.past-events {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
