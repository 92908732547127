.backButton {
	margin: 0.5rem 0 0 0;
	display: flex;
	align-items: center;
	background: none;
	border: none;
	font-size: 0.75rem;
	cursor: pointer;

	.spanBackButton {
		font-size: 1rem;
		font-weight: bold;
		margin-right: 0.25rem;
	}
}
