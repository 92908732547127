@import '../../../styles/variables';
@import '../../../styles/mixins';

.toggleFilters {
	width: 12.5rem;
	margin: 1rem 0;
	padding: 0.5rem;
	display: inline-flex;
	border: solid 2px $gris;
	border-radius: $border-radius * 2;
	align-items: center;
	justify-content: space-around;
	color: white;
	font-weight: bold;
	transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
	background-color: $color2;
	cursor: pointer;

	p {
		width: 10rem;
		margin: 0;
	}

	&.active {
		background-color: $color1;
	}

	.toggleSwitch {
		position: relative;
		width: 40px;
		height: 20px;
		background-color: #fff;
		border-radius: 15px;
		margin-right: 10px;
		transition: background-color 0.3s ease-in-out;

		&__circle {
			position: absolute;
			width: 18px;
			height: 18px;
			background-color: $color1;
			border-radius: 50%;
			top: 1px;
			left: 1px;
			transition: left 0.3s ease-in-out;
		}
	}

	/* Si le bouton est actif */
	&.active .toggleSwitch {
		background-color: #b4fe73; /* Changer le fond de toggleSwitch */
	}

	/* Si le bouton est actif, déplacer le cercle vers la droite */
	&.active .toggleSwitch__circle {
		left: 17px; /* Le cercle se déplace vers la droite */
		background-color: $color2;
	}
}
