@import '../../../styles/variables';
@import '../../../styles/mixins';

.citySearch {
	margin-bottom: 1rem;
	form {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 1rem;

		input {
			border: solid 2px $gris;
			border-radius: $border-radius 0 0 $border-radius;
			height: 42px;
			width: 225px;
			padding-left: 5px;
			// padding: 0.59rem;
			// font-size: 1rem;
		}

		button {
			margin: 0;
			background-color: $color2;
			color: white;
			border-radius: 50%;
			border: solid 2px $gris;
			padding: 16px;
			transform: translateX(-50%);
			transition: none;
			cursor: pointer;

			&:hover {
				background-color: $color1;
				box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); // Intensifie l'ombre
			}
		}
	}
}
