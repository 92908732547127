$color1: #d8b458;
$color2: #58bcd8;
$color3: #bcd858;
$color4: #7cd858;
$color5: #58d874;
$color6: #58d8b4;

$gris: #707070; //Couleur police

$border-radius: 12px;
