@import '../../../styles/variables';

.modal-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba($color1, $alpha: 0.3);
	z-index: 1000;
}

.modal {
	width: 90vw;
	max-height: 80vh;
	z-index: 1000;
	background: white;
	padding: 1rem;
	border-radius: $border-radius;
	position: relative;
	overflow: auto;

	&.carrousel {
		overflow: hidden;
	}
}

.modal-close {
	z-index: 1000;
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 10px;
	right: 10px;
	background: transparent;
	background-color: rgba($color1, $alpha: 0.6);
	border: none;
	border-radius: 50%;
	padding: 5px;
	width: 2rem;
	height: 2rem;
	text-align: center;
	color: $gris;
	font-size: 1.5rem;
	cursor: pointer;

	&:hover {
		color: white;
		background-color: $color1;
	}
}
