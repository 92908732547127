@import '../../styles/variables';
@import '../../styles/mixins';

.home {
	background-color: $color1;

	&__commandes {
		position: absolute;
		top: 1rem;
		right: 1rem;
		width: 3rem;
		height: 3rem;
		background-color: #d8b458d0;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		transition: width 0.3s ease, height 0.3s ease;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);

		// Icône du menu (bouton fermé)
		.icon {
			font-size: 1.5rem;
			color: white;
			transition: transform 0.3s ease;
		}

		// État du menu déployé
		&:hover, // Déclenchement par hover
		&.open {
			// Déclenchement par ajout de la classe 'open' pour le clic
			width: 15rem;
			height: auto;
			border-radius: 8px;
			padding: 1rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.icon {
				transform: rotate(
					90deg
				); // Rotation de l'icône pour indiquer l'ouverture
			}

			// Affiche les boutons avec animation
			button,
			.home__toggleFilters,
			.center-on-user {
				opacity: 1;
				transform: translateY(0);
				transition: opacity 0.3s ease, transform 0.3s ease;
			}
		}

		// Masque initial des boutons
		button,
		.home__toggleFilters,
		.center-on-user {
			opacity: 0;
			transform: translateY(
				-10px
			); // Décale légèrement les boutons vers le haut
			margin-top: 0.5rem;
			background-color: white;
			color: $color2;
			border: none;
			padding: 0.5rem 1rem;
			border-radius: 4px;
			font-size: 0.9rem;
			box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
			cursor: pointer;
			transition: opacity 0.3s ease, transform 0.3s ease;

			&:hover {
				background-color: $color2;
				color: white;
			}
		}
	}
}
