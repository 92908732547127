@import '../../../styles/variables';
@import '../../../styles/mixins';

.mapCard {
	@include elementMenu;
	width: 450px;
	// height: auto;
	// max-width: 80vw;
	// box-sizing: border-box;
	justify-content: center;
	margin-bottom: 10px;
	background-color: white;

	// &:hover {
	// 	transition: 0.1s;
	// 	transform: scale(1.01);
	// 	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.4);
	// }

	h4 {
		text-align: center;
		color: $gris;
		margin: 12px 0 12px 0;
		word-break: break-word;
	}

	&__tags {
		margin: 0 0.25rem 0.5rem 0.5rem;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		gap: 5px;
		color: $gris;

		.selected {
			background-color: $color2;
			color: white;
		}
	}

	&__tag {
		border: solid 2px $gris;
		border-radius: $border-radius * 2;
		padding: 5px 10px;
		font-weight: bold;
		text-align: center;
		background: white;
	}
}
