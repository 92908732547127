@import '../../../styles/mixins';

.favoriteBtn {
	background: none;
	border: none;
	cursor: pointer;

	// &-add {
	// 	&:hover {
	// 		color: red;
	// 	}
	// }

	&-remove {
		color: red;
	}
}
