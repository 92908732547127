@import '../../styles/variables';
@import '../../styles/mixins';

.authChoice__main {
	max-width: 100vh;

	&__body {
		@include bgMenu;
		display: flex;
		flex-direction: column-reverse;
		justify-content: space-around;

		.authChoice__main__info {
			display: flex;
			gap: 2rem;
			justify-content: space-around;
			flex-direction: column;

			.authChoice__card {
				margin-bottom: 2rem;
				width: 11rem;
				height: 14rem;
				box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
				border: solid 5px white;
				border-radius: $border-radius;
				display: flex;
				flex-direction: column;
				align-items: center;

				&.card1 {
					background-color: $color2;
					color: #fff;
					text-align: right;

					h4 {
						margin: 1.4rem 0 0 0;
						font-weight: bold;
						background-color: $color4;
					}

					img {
						margin: 0;
						height: 2rem;
						width: 2rem;
					}

					.card-title {
						margin: 0;
						text-align: center;
						font-size: 1.2rem;
					}

					.card-text {
						margin: 0.5rem 0 0 0;
						width: 13rem;
						padding: 5px;
						color: $gris;
						text-align: center;
						font-weight: bold;
						background-color: #fff;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
					}

					.material-symbols-outlined {
						width: 100%;
						font-size: 2.75rem;
					}
				}

				&.card2 {
					background-image: url(../../assets/presentation_addPlace.jpg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					background-color: $color1;
					display: flex;
					justify-content: space-evenly;

					p {
						margin: 0 5px;
						text-align: center;
						background-color: white;
						color: $gris;
						font-size: 1.2rem;
						font-weight: bold;
						box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
					}

					// Icône superposée
					.material-symbols-outlined {
						bottom: 1rem;
						right: 1rem;
						font-size: 2.8rem;
						color: white;
						text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
					}
				}

				&.card3 {
					background-image: url(../../assets/presentation_patrimoine.jpg);
					background-repeat: no-repeat;
					background-position: center;
					background-size: cover;
					background-color: $color3;
					display: flex;
					flex-direction: column;
					justify-content: flex-end;

					p {
						margin: 0 0.75rem 1.5rem 0.75rem;
						text-align: center;
						backdrop-filter: blur(2px);
						color: white;
						font-size: 1.2rem;
						font-weight: bold;
						text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5); // Ajoute une ombre pour améliorer la lisibilité
					}
				}
			}
		}

		.authChoice {
			margin: 2rem;
			padding: 1rem;
			background-color: white;
			border-radius: $border-radius;

			.authCoice__menu {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				h1 {
					margin: 2rem 0 5px 0;
					width: 20rem;
					text-align: center;
				}

				&__maxime {
					margin: 0 0 3.5rem 0;
					padding: 10px;
					border-radius: $border-radius;
					color: #fff;
					text-align: center;
					background-color: $color1;
				}

				.authChoice__button {
					@include button;
					width: 15rem;
					height: 2.5rem;
					margin: 0 0 1rem 0;
					display: flex;
					align-items: center;
					justify-content: center;

					img {
						height: 1.5rem;
						margin-right: 0.5rem;
					}
				}

				.authChoice__buttonNoLog {
					margin-top: 2rem;
					color: $gris;
					text-decoration: underline;
					background: none;
					border: none;
					font-size: 1rem;
					cursor: pointer;

					&:hover,
					&:focus {
						text-decoration: underline;
					}
				}
			}
		}
	}

	footer {
		width: 100vw;
		display: flex;
		justify-content: space-between;
		align-items: center;
		background-color: $gris;
		a {
			margin-left: 1rem;
			text-decoration: none;
			color: #fff;
		}

		.signalButton-contact {
			width: auto;

			.material-symbols-outlined {
				color: #fff;
			}
		}
	}
}
