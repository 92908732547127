@import './variables';

@mixin bgMenu {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
	width: 100vw;
	background-image: url(../../assets/MapBackGround2.webp);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@mixin elementMenu {
	width: 95%;
	margin-bottom: 1.5rem;
	border: solid 2px $gris;
	border-radius: $border-radius * 2;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@mixin formMenu {
	margin: 2rem;
	display: flex;
	flex-direction: column;
	align-items: center;

	label {
		margin-bottom: 0.25rem;
		font-weight: bold;
	}

	input,
	select {
		width: 15rem;
		margin-bottom: 30px;
		padding: 0.5rem;
		border: solid 2px $gris;
		border-radius: $border-radius * 2;

		&:focus {
			border: solid 2px $color2;
		}
	}

	.login__loginButton,
	.login__initButton {
		@include button;
	}

	.login__resetButton {
		margin-bottom: 2rem;
		background: none;
		border: none;
		cursor: pointer;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}

	p {
		width: 80%;
		text-align: center;
	}
}
@mixin message {
	max-width: 60%;
	border-radius: $border-radius;
	padding: 5px;
	text-align: justify;
	user-select: none;
	background-color: rgba($color1, 0.6);
}

@mixin button {
	width: 10rem;
	margin: 1rem 0;
	padding: 12px;
	background-color: $color2;
	color: white;
	font-weight: bold;
	border: solid 2px $gris;
	border-radius: $border-radius * 2;
	cursor: pointer;
	transition: 0.3s ease;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); // Ajoute une ombre pour un effet de profondeur

	&:hover {
		background-color: $color1;
		box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); // Intensifie l'ombre
	}

	&:active {
		background-color: darken(
			$color1,
			10%
		); // Rendre la couleur plus sombre lors du clic
		transform: translate(1px, 1px);
		box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.4); // Réduit l'ombre pour simuler l'enfoncement
		transition: transform 0.3s ease, box-shadow 0.1s ease; // Transition rapide pour le clic
	}
}

@mixin button-disabled {
	width: 10rem;
	margin: 1rem 0;
	padding: 0.5rem;
	background-color: $gris;
	color: white;
	font-weight: bold;
	border: solid 2px $gris;
	border-radius: $border-radius * 2;
	opacity: 0.5;
	cursor: pointer;
}

@mixin reduceBtn {
	width: 100%;
	padding-right: 1.5rem;
	margin-top: 2px;
	margin-bottom: 5px;
	position: relative;
	display: flex;
	align-items: end;
	justify-content: end;

	&-comment {
		margin: 0;
		padding: 0;
		width: auto;
	}

	.reportButton {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		background-color: transparent;
		border: none;
		padding: 0.5rem;
		cursor: pointer;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		transition: width 0.3s ease, background-color 0.3s ease;

		// Icône
		.material-icons {
			font-size: 24px;
			color: #f39c12;
			margin-left: 0.5rem;
			transition: color 0.3s ease;
		}

		// Texte visible mais masqué avec opacity et transform pour les transitions
		.buttonText {
			pointer-events: none;
			color: #f39c12;
			font-size: 14px;
			margin-right: 0.5rem;
			opacity: 0;
			transform: translateX(10px); // Décalage initial pour l'animation
			transition: opacity 0.3s ease, transform 0.3s ease;
			white-space: nowrap; // Empêche le retour à la ligne
		}

		// Style au survol pour élargir et afficher le texte
		&:hover {
			width: auto;
			padding-left: 1rem;
			background-color: #f39c12;
			color: #fff;
			border-radius: 20px;

			.material-icons {
				color: #fff;
			}

			.buttonText {
				pointer-events: auto;
				opacity: 1;
				transform: translateX(0); // Animation vers sa position finale
				color: #fff;
			}
		}
	}
}
