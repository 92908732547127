@import '../../../styles/variables';
@import '../../../styles/mixins';

.addPlaceForm {
	@include formMenu;

	width: 95%;
	margin: 0;
	text-align: center;

	&__tagsBox {
		width: 110%;
		border: none;
	}

	h2 {
		margin: 0 0 1rem 0;
	}
	h4 {
		margin: 1rem 0 0.25rem 0;
	}

	.success {
		margin: 1rem 0 0.5rem 0;
		color: $color4;
	}

	.error {
		margin: 1rem 0 0.5rem 0;
		color: red;
	}

	textarea {
		width: 85%;
		max-width: 25rem;
		height: 100px;
		resize: none;
		padding: 0.5rem;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	.address-suggestions {
		border: 1px solid #ccc;
		max-height: 150px;
		overflow-y: auto;
		position: absolute;
		background: #fff;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 95%;
		z-index: 1000;
		li {
			padding: 10px;
			cursor: pointer;
			&:hover {
				background-color: #f0f0f0;
			}
		}
	}

	#descriptionLabel,
	#traductionLabel {
		margin-top: 1rem;
	}

	.messageLength {
		width: 85%;
		max-width: 25rem;
		font-size: 0.75rem;
		text-align: right;
	}

	.messageError {
		color: red;
	}

	.addPlaceForm__ltdlng,
	.addPlaceForm__cityAndPostalCode {
		display: flex;
		justify-content: space-between;
		gap: 10px;

		.addPlaceForm__ltd,
		.addPlaceForm__lng,
		.addPlaceForm__city,
		.addPlaceForm__postalCode {
			display: flex;
			flex-direction: column;
			input {
				width: 6rem;
			}
		}
	}

	&__pointOfInterest {
		margin: 0;

		input,
		label {
			margin: 0;
			width: auto;
			word-break: break-word; // Permet de couper le texte du label si nécessaire
		}

		input {
			height: 1rem;
			margin: 0 0 30px 0;
		}

		label {
			margin-left: 10px;
			font-size: 1.25rem;
			cursor: pointer;

			&:hover {
				color: #28a745;
			}
		}
	}

	&__commande {
		margin-top: 1rem;
		.addPlaceForm__button {
			@include button;
		}
	}

	&__modal {
		overflow: auto;
		max-width: 85%;
		max-height: 85%;

		.modal-content {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}

	// Styles pour la zone de drag-and-drop et la galerie de prévisualisation des photos
	&__picturesAndPreviews,
	&__picturePreviews {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 10px;
	}

	&__pictures {
		position: relative;
		width: 10rem;
		height: 9rem;
		margin: 0 5px;
		// padding: 1rem;
		border: 2px dashed $gris;
		border-radius: $border-radius;
		background-color: white;
		cursor: pointer;
		transition: 0.3s ease;
		text-align: center;

		&:hover {
			background-color: #f0f0f0;
		}

		// Message d'instruction
		p {
			width: 100%;
			text-align: center;
			color: #888;
			font-size: 0.9rem;
			margin: 1rem 0;
		}

		span {
			font-size: 2rem;
		}

		// Champ input de fichier qui couvre toute la zone de drag-and-drop
		input[type='file'] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			opacity: 0;
			cursor: pointer;
		}
	}

	.picturePreview {
		position: relative;
		width: 10rem;
		height: 9rem;
		margin: 0 5px;
		border-radius: $border-radius;
		overflow: hidden;
		background-color: #e0e0e0;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		// Bouton de suppression
		&__remove {
			position: absolute;
			top: 5px;
			right: 5px;
			color: rgba(255, 0, 0, 0.8);
			background-color: white;
			border: none;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			padding: 0;
			font-size: 1.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			opacity: 0.6;
			transition: opacity 0.3s ease;

			&:hover {
				opacity: 1;
			}
		}
	}

	&__commande {
		display: flex;
		gap: 10px;
	}
}
