@import '../../styles/variables';
@import '../../styles/mixins';

.login {
	@include bgMenu;

	h2 {
		margin: 2rem 0 1rem 0;
		width: 20rem;
		text-align: center;
	}

	form {
		@include formMenu;

		.login__initButton-backButton {
			display: flex;
			align-items: center;
			background: none;
			border: none;
			font-size: 0.75rem;
			cursor: pointer;

			.spanBackButton {
				font-size: 1rem;
				font-weight: bold;
				margin-right: 0.25rem;
			}
		}
	}
}
