@import './variables';

* {
	@media (min-width: 768px) {
		font-size: 18px;
	}

	@media (min-width: 1200px) {
		font-size: 20px;
	}

	@media (min-width: 1400px) {
		font-size: 24px;
	}
}

body {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-size: 16px;
	color: $gris;
	cursor: default;
	font-family: -apple-system, BlinkMacSystemFont, 'Bahnschrift', verdana,
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	h1 {
		font-size: 2.5rem;
	}
	h2 {
		font-size: 2rem;
	}
	h3 {
		font-size: 1.5rem;
	}
	h4 {
		font-size: 1.25rem;
	}

	// button {
	// 	&:focus {
	// 		outline: none;
	// 	}
	// }
}
