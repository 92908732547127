@import '../../styles/mixins';

.signup {
	@include bgMenu;

	form {
		@include formMenu;

		select {
			width: 16.25rem;
		}

		p {
			margin: 0;
		}
	}

	&__tick {
		max-width: 26rem;
		margin-bottom: 2rem;
	}

	&__cgu,
	&__contact {
		margin: 1rem 0 0 0;

		label {
			display: flex;
			a {
				margin-left: 5px;
			}

			span {
				display: block;
				width: 100%;
			}

			.signup__cgu-input {
				margin: 0 10px 0 0;
				width: auto;
			}
		}
	}

	.tagsBox-signup {
		margin: 0;
		// background-color: white;
		background: none;
		border: none;

		.tagsBox__list {
			margin: 0 1rem 1rem 1rem;
			border: solid 5px $color1;
		}
	}
}
