@import '../../../styles/variables';
@import '../../../styles/mixins';

.event__history {
	&__noEvent {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 10rem;
		}
	}
	&__list-ongoing-events,
	&__list-past-events {
		display: flex;
		flex-direction: column;
		align-items: center;

		.ongoing-events,
		.past-events {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			grid-gap: 1rem;

			.event__history__item {
				&__infos {
					margin: 10px;
					span {
						font-weight: bold;
					}
				}
			}
		}
	}

	.year-separator {
		width: 20rem;
		height: 11.5rem;
		margin: 0;
		border: solid 2px $gris;
		border-radius: 2 * $border-radius;
		display: flex;
		justify-content: center;
		align-items: center;

		h2 {
			font-size: 4rem;
		}
	}
}
