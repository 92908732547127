@import '../../styles/variables';
@import '../../styles/mixins';

.boardPro {
	&__nav {
		width: 100%;
		background-color: #f8f9fa; // Couleur de fond douce
		box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); // Ombre légère
		position: relative;

		// Titre
		&__title {
			width: 100%;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 1.5rem;
			color: #343a40;
			font-weight: bold;
			margin: 0;

			&--img {
				display: none;
				border: none;
				background: none;
				margin-right: 20px;
				height: 4rem;
				padding: 0;
				cursor: pointer;

				img {
					height: 100%;
				}
			}
		}

		// Bouton retour
		&__back {
			margin-left: 1rem;
			background: none;
			border: none;
			cursor: pointer;
			font-size: 1.5rem;
			color: #495057;
			display: flex;
			align-items: center;
			// transition: transform 0.2s ease, color 0.2s ease;

			&:hover {
				transform: scale(1.1);
				color: $color1;
			}
		}

		&__titleGroupe {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 1rem 0;
			width: 100%;
		}

		// Boutons du menu bandeau
		&__btnGroupe {
			display: none;
			justify-content: space-evenly;
			padding: 1rem;
			border-top: 1px solid #e9ecef;
		}
		&__btn {
			flex: 1;
			margin: 0 0.5rem;
			padding: 0.5rem 0rem;
			background-color: #ffffff;
			border: solid 2px #dee2e6;
			border-radius: $border-radius;
			font-size: 1rem;
			color: $gris;
			text-align: center;
			cursor: pointer;
			transition: background-color 0.2s ease, color 0.2s ease,
				transform 0.2s ease;

			&:hover {
				background-color: $color1;
				color: white;
				transform: scale(1.01);
			}

			&.active {
				font-weight: bold;
				background-color: $color1;
				color: white;
				border: solid 2px $gris;
			}
		}

		// Menu hamburger (pour écrans < 768px)
		&__hamburger {
			width: 100%;
			margin-right: 1rem;
			width: 2rem;
			height: 2rem;
			color: $color1;
			background: none;
			border: none;
			cursor: pointer;
			transition: all 0.1s ease-in-out;

			&:hover {
				color: $color2;
			}

			&.active {
				transform: rotate(-90deg);
				color: $color2;
			}
		}

		// Menu déroulant pour le hamburger
		&__dropdown {
			display: none;
			flex-direction: column;
			position: absolute;
			top: 100%;
			right: 0;
			width: 100%;
			background-color: #ffffff;
			box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
			z-index: 150;

			&.active {
				display: flex;
			}

			&__btn {
				padding: 1rem;
				text-align: center;
				border-bottom: 1px solid #e9ecef;
				color: $gris;
				background: none;
				cursor: pointer;
				transition: background-color 0.2s ease, color 0.2s ease;

				&:hover {
					background-color: $color1;
					color: white;
				}
			}
		}
	}

	&__main {
		display: flex;
		justify-content: center;
		// margin: 1rem 0.5rem;

		&__corps {
			margin: 0 2rem;
		}

		.signalForm {
			width: 100%;

			textarea {
				height: 10rem;
			}
		}
	}
}
