@import '../../styles/variables';

.noFound {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 3rem auto;
	text-align: center;

	h1,
	h2,
	a {
		margin: auto;
		font-size: 8rem;
		color: $color1;
	}

	h2 {
		font-size: 1.5rem;
		color: $color2;
	}

	a {
		margin: 2rem 0 5rem 0;
		font-size: 1rem;
		color: black;
		text-decoration: underline;

		&:hover {
			color: $color2;
		}
	}
}
