@import '../../../styles/variables';
@import '../../../styles/mixins';

.popup {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: rgba(255, 255, 255, 0.95);
	border: 1px solid #ccc;
	border-radius: 8px;
	padding: 1rem;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
	min-width: 280px; // Taille par défaut de la popup

	button {
		border: none;
		cursor: pointer;
		background: none;
	}

	&.changelog,
	&.preferences {
		z-index: 2000;
		width: 90%;
		max-height: 75%;
		overflow: auto;
		background-color: rgba(255, 255, 255, 1);

		.popup__main {
			&__title {
				text-align: center;
				font-size: 1.1rem;

				strong {
					font-size: 1.1rem;
				}
			}

			.tagsBox__majButton {
				@include button;
			}

			&__signature {
				margin-bottom: 2rem;
				font-style: italic;
				font-size: 1.2rem;
				width: 90%;
				text-align: right;
			}

			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.popup__action {
				@include button;
			}
		}
		.popup__close {
			position: absolute;
			top: 10px;
			right: 20px;
			font-size: 1.5rem;

			&:hover {
				color: $color2;
			}
		}
	}

	&.changelog {
		.popup__main {
			text-align: justify;
		}
	}

	// Styles spécifiques si la classe email est présente
	&.email {
		z-index: 1000;
		top: 0;
		left: 0;
		transform: none;
		width: 100%;
		height: auto;
		display: flex;
		justify-content: space-between;
		align-items: center;
		box-sizing: border-box;
		padding: 0.1rem 1rem;
		background-color: rgba(0, 0, 0, 0.7);
		color: white;
		box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
		border-radius: 0;
		border: none;

		.popup__main {
			display: flex;
			align-items: center;

			p {
				font-size: 0.75rem;
				margin: 0;
			}

			button {
				margin-left: 10px;
				padding: 0;
				font-size: 0.75rem;
				color: $color1;

				&:hover {
					color: darken($color2, 10%);
				}
			}
		}

		// Bouton fermer en X
		.popup__close {
			background: none;
			color: white;
			font-size: 1.5rem;
			padding: 0;
			margin: 0;
			border: none;
			cursor: pointer;

			&:hover {
				color: lighten(white, 20%);
			}
		}
	}
}
