@import '../../../styles/variables';

.rating__message {
	// margin: 0.5rem 0 0 0.5rem;
	font-weight: lighter;
}

.rating {
	// height: 2rem;
	margin: 5px 0 0 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: lighter;

	.rating__star {
		display: flex;
		margin: 0 0.5rem 0 0.5rem;
	}

	.rating__note {
		font-weight: bold;
		margin-right: 0.25rem;
	}

	.rating__nbrNote {
		margin: 0 0.5rem 0 0;
		font-weight: lighter;
	}

	.rating__star-full {
		display: flex;
		align-items: center;
		color: $color1;
		// background-color: $color1;
	}

	.rating__star-empty {
		color: rgba($color: #777, $alpha: 1);
		display: flex;
		align-items: center;
	}

	.rating__evaluation {
		margin: 0 5px;
	}
}
