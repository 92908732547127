@import '../../../styles/variables';
@import '../../../styles/mixins';

.map-controls {
	position: absolute;
	top: 2.75rem;
	right: 10px;
	display: flex;
	flex-direction: row;
	gap: 10px;
	align-items: center;
	// z-index: 1;

	&__button,
	.reportButton {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color2;
		border: none;
		border-radius: 50%;
		padding: 12px;
		margin: 0;
		width: 50px;
		height: 50px;
		color: #fff;
		cursor: pointer;
		box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);

		&:hover {
			background-color: $color1;
		}

		.buttonText {
			display: none;
		}
	}

	&__button .material-icons {
		font-size: 1rem;
	}

	&__toggle-filters {
		margin: 5px 0;
	}
}

.activeFilters {
	background-color: $color1;
}
