@import '../../../styles/variables';

.mapContainer {
	height: 100vh;
	width: 100%;
	overflow: clip;

	.marker {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		justify-content: center;

		&.place-marker {
			color: #a2341b;

			.material-symbols-outlined {
				font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0,
					'opsz' 48;
				font-size: 1.25rem;
			}

			&:hover {
				cursor: pointer;
				color: #ef4f2b;
			}

			&.highlighted-marker {
				color: #ef4f2b; // Couleur spéciale pour le marker sélectionné
			}

			&.POI {
				color: #ca940a;

				&:hover {
					cursor: pointer;
					color: #ef4f2b;
				}

				&.highlighted-marker {
					color: #ef4f2b; // Couleur spéciale pour le marker sélectionné
				}
			}
		}

		&.event-marker {
			color: #a2341b;

			.material-symbols-outlined {
				font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0,
					'opsz' 48;
				font-size: 1.5rem;
			}

			&:hover {
				cursor: pointer;
				color: #ef4f2b;
			}

			&.highlighted-marker {
				color: #ef4f2b; // Couleur spéciale pour le marker sélectionné
			}
		}
	}

	.mapCardsContainer {
		position: absolute;
		left: var(--tooltip-left, 0px);
		top: var(--tooltip-top, 0px);
		z-index: 1000;
		max-width: 90vw;
		box-sizing: border-box;
		padding: 0.5rem;
		/* Valeur par défaut : tooltip centré horizontalement et affiché au-dessus */
		transform: translate(-50%, -105%);
		pointer-events: none;
	}

	/* Si le tooltip doit être affiché à gauche du marqueur */
	.mapCardsContainer.tooltip--left {
		transform: translate(-100%, -100%);
	}

	/* Si le tooltip doit être affiché à droite du marqueur */
	.mapCardsContainer.tooltip--right {
		transform: translate(0, -100%);
	}

	/* Si le tooltip doit être affiché en dessous du marqueur */
	.mapCardsContainer.tooltip--bottom {
		transform: translate(-50%, 0);
	}

	/* Combinaisons si nécessaire */
	.mapCardsContainer.tooltip--left.tooltip--bottom {
		transform: translate(-100%, 0);
	}
	.mapCardsContainer.tooltip--right.tooltip--bottom {
		transform: translate(0, 0);
	}
}
