@import '../../../styles/variables';
@import '../../../styles/mixins';

.addEventForm {
	@include formMenu;
	max-width: 20rem;
	margin: 1.5rem;
	padding: 1.5rem 3rem;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.5);
	text-align: center;

	h2 {
		margin: 2rem 0 0rem 0;
	}

	h3 {
		margin: 2rem 0 1rem 0;
	}

	h4 {
		margin: 1rem 0 0 0;
	}

	&__tagsBox {
		width: auto;
		border: none;

		h4 {
			margin: 2rem 0 1rem 0;
		}

		.tagsBox__list {
			margin: 0;
			width: 85%;
		}
	}

	.success {
		margin: 1rem 0 0.5rem 0;
		color: $color4;
	}

	.error {
		margin: 1rem 0 0.5rem 0;
		color: red;
	}

	textarea {
		width: 85%;
		max-width: 25rem;
		height: 100px;
		resize: none;
		padding: 0.5rem;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	.address-suggestions {
		border: 1px solid #ccc;
		max-height: 150px;
		overflow-y: auto;
		position: absolute;
		background: #fff;
		list-style: none;
		margin: 0;
		padding: 0;
		width: 95%;
		z-index: 1000;
		li {
			padding: 10px;
			cursor: pointer;
			&:hover {
				background-color: #f0f0f0;
			}
		}
	}

	.messageLength {
		width: 85%;
		max-width: 25rem;
		font-size: 0.75rem;
		text-align: right;
	}

	.messageError {
		color: red;
	}

	&__ltdlng,
	&__cityAndPostalCode,
	&__startTimeAndEndTime {
		display: flex;
		justify-content: center;
		gap: 20px;

		.addEventForm__ltd,
		.addEventForm__lng,
		.addEventForm__city,
		.addEventForm__postalCode {
			display: flex;
			flex-direction: column;
			input {
				width: 6rem;
				text-align: center;
			}
		}
	}

	&__mapPreview {
		display: flex;
		flex-direction: column;
		align-items: center;
		p {
			margin: 0;
			width: auto;
			max-width: 16rem;
		}
	}

	&__dateAndDuration {
		margin-top: 2rem;
		max-width: 16rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.addEventForm__date {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			label {
				font-size: 1.2rem;
			}

			.slider-container {
				display: flex;
				align-items: center;
				gap: 10px;
			}

			.react-datepicker-wrapper {
				width: 100%; /* S'assure que le calendrier s'adapte */
			}

			.react-datepicker__input-container input {
				// width: 100%;
				padding: 8px;
				border: 1px solid #ccc;
				border-radius: 4px;
			}
		}

		#startDate {
			margin-bottom: 0;
		}

		#eventDurationLabel {
			margin-top: 30px;
		}

		#eventDuration {
			margin: 0 0 30px 0;
			text-align: center;
		}
	}

	&__dailyHours {
		.addEventForm__sameTimeOption {
			margin: 0;

			input,
			label {
				margin: 0;
				width: auto;
				word-break: break-word; // Permet de couper le texte du label si nécessaire
			}

			input {
				height: 1rem;
				margin: 0 0 30px 0;
			}

			label {
				margin-left: 10px;
				font-size: 1.25rem;
				cursor: pointer;

				&:hover {
					color: #28a745;
				}
			}
		}

		.addEventForm__dailyHoursOption {
			display: flex;
			justify-content: center;
			flex-direction: row;
			flex-wrap: wrap;
			gap: 25px;

			.addEventForm__dailyHours__day {
				.addEventForm__dailyHours__title {
					color: $color1;
					font-weight: bold;
					margin-bottom: 10px;
				}
				.addEventForm__dailyHours__inputs {
					display: flex;
					gap: 10px;

					.addEventForm__startTime,
					.addEventForm__endTime {
						display: flex;
						flex-direction: column;
						input {
							width: 6rem;
							text-align: center;
							margin: 0 0 30px 0;
						}
					}
				}
			}
		}
	}

	&__additionalInfo {
		// max-width: 17rem;

		.eventInfo {
			display: flex;
			flex-direction: column;
			align-items: center;

			&__title {
				margin-bottom: 30px;
				h3 {
					margin-bottom: 0;
				}
			}

			&__item {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-bottom: 30px;

				label,
				select {
					margin-right: 10px;
				}
				label,
				input,
				select {
					margin-bottom: 0;
				}

				select {
					width: auto;
					margin-right: 10px;
				}

				&.freeEventOption {
					flex-direction: row;
					margin-top: 30px;
					height: 2rem;

					input,
					label {
						width: auto;
						word-break: break-word; // Permet de couper le texte du label si nécessaire
					}

					input {
						// height: 1rem;
						width: 80px;
					}

					#freeEventLabel {
						cursor: pointer;
						font-size: 1.25rem;
						margin-right: 60px;
						&:hover {
							color: #28a745;
						}
					}
				}
			}
		}
	}

	.addEventForm__selectPrice {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		width: 100%;
		margin: 1rem auto;

		p {
			font-size: 0.75rem;
			margin: 0;
			text-align: center;
			width: 100%;

			span {
				font-size: 0.85rem;
				font-weight: bold;
				text-decoration: underline;
				color: $color1;
			}
		}

		&__beforeStartDay {
			margin: 30px 0;
			span {
				color: #28a745;
			}
		}

		.priceCards {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			gap: 24px;
		}

		.priceCard {
			border: 2px solid #ddd;
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			padding: 15px;
			border-radius: 8px;
			width: 7rem;
			height: 10rem;
			background-color: rgba($gris, $alpha: 0.2);
			text-align: center;
			cursor: pointer;
			transition: transform 0.3s ease, border-color 0.3s ease;

			.price-day {
				font-size: 0.75rem;
				span {
					white-space: nowrap;
					font-size: 0.75rem;
				}
			}

			&:hover {
				transform: translateY(-5px);
				border-color: $color1;
				background-color: white;
			}

			&.selected {
				border-color: $color1;
				box-shadow: 0 0 10px rgba($color1, 0.9);
				background-color: white;

				.price {
					color: $color1;
				}

				.priceCard__discount {
					color: #28a745;
					scale: 1.1;
				}
			}

			&.bestOffer {
				border-color: #28a745;
				background-color: #e8f5e9;
			}

			&.freeOption {
				width: 10rem;
				height: 12rem;
				border-color: $color1;
				cursor: default;

				&:hover {
					transform: translateY(0);
				}

				.freeText {
					font-size: 1.2rem;
					color: #28a745;
				}

				.freeTextInfo {
					font-weight: lighter;
				}
			}

			&__duration {
				font-size: 1.3rem;
				font-weight: bold;
				margin-bottom: 10px;
			}

			&__price {
				.price {
					font-size: 1.2rem;
					font-weight: bold;
				}
			}

			&__discount {
				font-size: 0.9em;
				font-weight: bold;
				margin-top: 10px;
			}
		}

		// Message sous le menu
		&Message {
			font-size: 0.875rem;
			color: #6c757d;
			background-color: #f8f9fa;
			padding: 0.75rem;
			border-radius: $border-radius;
			border: 1px solid #ced4da;
			box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
		}
	}

	&__button {
		@include button;
	}

	// Styles pour la zone de drag-and-drop et la galerie de prévisualisation des photos
	&__picturesAndPreviews,
	&__picturePreviews {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		// gap: 10px;
	}

	&__pictures {
		position: relative;
		width: 10rem;
		height: 9rem;
		margin: 24px 5px;
		// padding: 1rem;
		border: 2px dashed $gris;
		border-radius: $border-radius;
		background-color: white;
		cursor: pointer;
		transition: 0.3s ease;
		text-align: center;

		&:hover {
			background-color: #f0f0f0;
		}

		// Message d'instruction
		p {
			width: 100%;
			text-align: center;
			color: #888;
			font-size: 0.9rem;
			margin: 1rem 0;
		}

		span {
			font-size: 2rem;
		}

		// Champ input de fichier qui couvre toute la zone de drag-and-drop
		input[type='file'] {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
			opacity: 0;
			cursor: pointer;
		}
	}

	.picturePreview {
		position: relative;
		width: 10rem;
		height: 9rem;
		margin: 0 5px;
		border-radius: $border-radius;
		overflow: hidden;
		background-color: #e0e0e0;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		// Bouton de suppression
		&__remove {
			position: absolute;
			top: 5px;
			right: 5px;
			color: rgba(255, 0, 0, 0.8);
			background-color: white;
			border: none;
			border-radius: 50%;
			width: 24px;
			height: 24px;
			padding: 0;
			font-size: 1.2rem;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			opacity: 0.6;
			transition: opacity 0.3s ease;

			&:hover {
				opacity: 1;
			}
		}
	}

	&__commande {
		display: flex;
		gap: 10px;
	}

	&__preview {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: rgba($color2, 0.3);
		border-radius: $border-radius * 2;

		h2 {
			text-align: center;
		}

		&__title {
			width: 95%;
			max-width: 40rem;
		}

		&__corps {
			// margin: 0 0 1rem 1rem;
			width: 95%;
			max-width: 40rem;

			span {
				font-weight: bold;
			}

			h3,
			h4 {
				margin: 0 0 1rem 0;
				text-align: left;
			}
		}

		&__price {
			margin: 1rem 0;
			span {
				color: #28a745;
				font-weight: bold;
				font-size: 1.25rem;
			}
		}

		&__duration {
			span {
				color: #28a745;
				font-size: 1.2rem;
			}
		}

		.success {
			color: #28a745;
			text-align: center;
		}

		&__button {
			display: flex;
			flex-direction: column;
			justify-content: center;
			// gap: 2rem;
			button {
				@include button;
			}
		}

		&__message {
			width: 100%;
			margin: 0 50px 10px 0;
			text-align: end;
			font-style: italic;
		}
	}
}
