@import '../../../styles/variables';

.menuOpen {
	position: absolute;
	width: 100%;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: $border-radius $border-radius 0 0;

	&-aroundYou {
		.tabs {
			width: 100%;
			display: flex;
			// flex-direction: row-reverse;
			// justify-content: flex-end;

			.tab-button {
				width: 100%;
				color: $gris;
				background-color: rgba($gris, $alpha: 0.5);
				border: none;
				padding: 16px 0.5rem;
				text-align: center;
				cursor: pointer;

				&:hover {
					background-color: rgba($color1, $alpha: 0.7);
				}

				&.active {
					font-weight: bold;
					color: #fff;
					background-color: rgba($color1, $alpha: 1);
				}
			}
		}

		.tab-content {
			padding-top: 24px;
			align-items: center;
			display: flex;
			flex-direction: column;
			border-top: solid 2px $gris;
			width: 100%;
			// background-color: rgba($color1, $alpha: 0.15);

			// &-places {
			// 	background-color: rgba($color1, $alpha: 0.5);
			// }

			&-events {
				width: 100%;
				min-height: 500px;
				// background-color: rgba($color2, $alpha: 0.5);
			}

			&-favorites {
				width: 100%;
				min-height: 500px;
				// background-color: rgba($color4, $alpha: 0.5);
			}
		}
	}

	&-nextAdventure {
		.menuOpen__head {
			display: flex;
			flex-direction: row;
			align-items: center;
			// margin: 3rem 0 2rem 0;

			h2 {
				transform: translateX(-1rem);
			}

			.backElement {
				&__btn {
					margin-right: 1rem;
					color: $gris;
					background: none;
					border: none;
					cursor: pointer;

					&:hover {
						color: $color2;
					}

					span {
						font-weight: bold;
					}
				}
			}
		}
	}

	&__head {
		margin: 0 0 2rem 0;

		h2 {
			text-align: center;
			margin: 0;
			font-size: 1.75rem;
		}
	}

	&__content {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		&__message {
			@include message;
		}
	}
}
