@import '../../../styles/variables';
.evaluation {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-weight: lighter;

	&-reverse {
		flex-direction: column-reverse;
	}

	&__anonymous {
		width: 85%;
		margin: 0 0 1rem 0;
		text-align: center;

		.evaluation__createAccountButton {
			margin: 0;
		}
	}

	.evaluation__rating {
		display: flex;
		align-items: center;
		width: 100%;
		margin: 1rem 0 0.5rem 2rem;

		.evaluation__star {
			margin-left: 0.5rem;
			display: flex;
			align-items: center;

			.star {
				padding-left: 2px;
				font-size: 1rem;
			}
			.star:hover {
				scale: 1.2;
			}

			.rating__star-full {
				display: flex;
				align-items: center;
				color: $color1;
				// background-color: $color1;
			}

			.rating__star-empty {
				color: rgba($color: #777, $alpha: 1);
				display: flex;
				align-items: center;
			}
		}
	}

	.evaluation__comment {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		textarea {
			width: 85%;
			height: 80px;
			resize: none;
			padding: 0.5rem;
			border: 1px solid #ccc;
			border-radius: 4px;
		}

		span {
			width: 100%;
			margin-right: 2rem;
			font-size: 0.6rem;
			text-align: end;
		}

		.commented {
			@include button;
		}

		.button-disabled {
			@include button-disabled;
			cursor: default;
		}

		.hasCommented {
			margin-bottom: 0.5rem;
			border: none;
			cursor: pointer;
			background: none;
			color: $color2;

			&:hover {
				color: $color1;
			}
		}

		p {
			text-align: center;
			// margin: 10px 1rem;
		}
	}

	.evaluation__createAccount {
		margin: 0 0 0 0.5rem;
		padding: 0.25rem;
		width: auto;
		font-weight: lighter;
	}

	.evaluation__usersComments {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		h3 {
			margin: 1rem 0 0.5rem 0;
		}

		&__button {
			@include button;
			margin: 0 0 1rem 0;
		}

		.evaluation__userComment {
			width: 90%;
			padding: 0.5rem;
			margin-bottom: 1rem;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border: solid 1px $gris;
			border-radius: $border-radius;

			.userComment {
				height: 1rem;
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				margin: 0 0 10px 0;

				.userComment__info {
					display: flex;
					align-items: center;

					.userComment__badge {
						img {
							width: 1.5rem;
						}
					}
				}
			}

			.comment {
				text-align: justify;
				font-weight: normal;
				margin: 0;
			}

			.comment-date-report {
				width: 100%;
				margin: 0;
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}
	}

	.infoMessage {
		margin: 0 2rem 0 2rem;
		color: red;
	}

	.modalComment {
		// width: 32rem;
		width: 90vw;
		max-height: 80vh;
		overflow: auto;
	}
}
