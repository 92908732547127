@import '../../../styles/variables';
@import '../../../styles/mixins';

.signalForm {
	// width: 90vw;
	// max-height: 80vh;
	margin: 0;
	text-align: center;
	overflow: auto;

	form {
		@include formMenu;
	}

	h2 {
		margin: 0 0 1rem 0;
	}
	h4 {
		margin: 1rem 0 0.25rem 0;
	}

	p {
		margin: 0 0 2rem 0;
	}

	.messageLength {
		width: 85%;
		max-width: 25rem;
		font-size: 0.75rem;
		text-align: right;
	}

	.error {
		color: red;
	}

	textarea {
		width: 85%;
		max-width: 25rem;
		height: 100px;
		resize: none;
		padding: 0.5rem;
		border: 1px solid #ccc;
		border-radius: 4px;
	}

	button {
		@include button;
	}
}
